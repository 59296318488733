<template>
  <div>
    <b-card v-if="!instance">
      <div class="mx-auto d-flex justify-content-center mb-3">
        <b-spinner small type="grow"></b-spinner>
      </div>
    </b-card>

    <b-card :class="status_class" v-else header-border-variant="white" no-body>
      
      <div class="">
        <h4 style="text-align: center;">{{ instance.name }}</h4>

        <p>Version: {{ instance.version }}</p>

        <p>{{ visual_time_since }} </p>

        <div v-if="instance.stats">
          <p>Users: {{ instance.stats.users }}</p>
          <p>Members: {{ instance.stats.members }}</p>
        </div>
        
      </div>
    </b-card>
  </div>
    
</template>

<script>
import axios from 'axios';
import dayjs from 'dayjs';
import { mapGetters } from 'vuex';
import {
  SET_USER_PREFS
} from '@/core/services/store/common.module';
import store from '@/core/services/store';


export default {
  name: 'DashboardInstanceStatus',
  components: {

  },
  props: ['instance'],
  data() {
    return {
      refresh: 0,
      interval_id: null
    };
  },
  computed: {
    ...mapGetters(['currentCompanyId', 'currentPeriodId','isAuthenticated','initComplete','user_prefs']),
    status_class() {
      this.refresh;

      const seconds_since = this.calculate_seconds_since();
      if (seconds_since === null) {
        return 'bg-light';
      }

      if (seconds_since <= 30) {
        return 'bg-success';
      } else if (seconds_since < 60) {
        return 'bg-warning';
      } else {
        return 'bg-danger';
      }
    },
    visual_time_since() {
      const minutes_since = this.calculate_minutes_since();
      if (minutes_since === null) {
        return '...'
      }

      if (minutes_since < 60) {
        return `${minutes_since} minutes`;
      } else {
        const hours = Math.floor(minutes_since / 60);
        const minutes = minutes_since % 60;
        return `${hours} hours, ${minutes} minutes`;
      }
    }
  },
  mounted() {

    if (this.interval_id) {
      return;
    }

    this.interval_id = setInterval(() => {
      this.refresh++;
    }, 1000);
  },
  watch: {
    currentCompanyId() {
    
    },
    currentPeriodId(newValue, oldValue) {
    
    }
  },
  methods: {
    calculate_seconds_since() {
      
      if (!this.instance || !dayjs(this.instance.last_polled_at).isValid()) {
        return null
      }

      const now = dayjs();
      const created = dayjs(this.instance.last_polled_at);
      return now.diff(created, 'seconds');
    },
    calculate_minutes_since() {
      if (!this.instance || !dayjs(this.instance.last_polled_at).isValid()) {
        return null
      }

      const now = dayjs();
      const created = dayjs(this.instance.last_polled_at);
      return now.diff(created, 'minutes');
    },
  }
};
</script>

<style lang="scss" scoped>

p {
  margin-bottom: 0px;
  margin-left: 8px;
}

  .date_box {
    border: 1px solid #1BC5BD;
    border-radius: 8px;
    width: 100px;
    padding: 6px;
    float: left;
  }

  .date_box_mid {
    float: left;
    width: 14px;
    height: 32px;
    margin-left: 8px;
    margin-top: 7px;
  }
  
    .custom-card-body-2-column {
      display: flex;
      flex-direction: row;
      flex-grow: 1;
      width: 100%;
      height: 100%;
      padding: 2rem 0rem;
      .title {
        flex: 1;
        position: relative;
        font-size: 2.2rem;
        font-weight: 600;
        color: #595d63;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          opacity: 0.08;
          position: absolute;
          z-index: 0;
          width: 120px;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
      .data {
        flex: 1;
        display: flex;
        align-items: center;
        margin-left: 3rem;
        .member-icon-1 {
          display: inline-block;
          width: 0.45rem;
          height: 0.45rem;
          flex: 0 0 0.45rem;
          border-radius: 100%;
          margin-bottom: 0.12rem;
          margin-right: 0.8rem;
          background-color: #93a2dd !important;
        }
        .member-icon-2 {
          display: inline-block;
          width: 0.45rem;
          height: 0.45rem;
          flex: 0 0 0.45rem;
          border-radius: 100%;
          margin-bottom: 0.12rem;
          margin-right: 0.8rem;
          background-color: #5d78ff !important;
        }
        .member-icon-3 {
          display: inline-block;
          width: 0.45rem;
          height: 0.45rem;
          flex: 0 0 0.45rem;
          border-radius: 100%;
          margin-bottom: 0.12rem;
          margin-right: 0.8rem;
          background-color: #646c9a !important;
        }
      }
    }
    .membership-acquisition {
      .gender-girl-line {
        width: 15px;
        height: 5px;
        border-radius: 3px;
        background-color: #5d78ff !important;
      }
      .gender-boy-line {
        width: 15px;
        height: 5px;
        border-radius: 3px;
        background-color: #93a2dd !important;
      }
      .gender-other-line {
        width: 15px;
        height: 5px;
        border-radius: 3px;
        background-color: #646c9a !important;
      }
    }
  
</style>
