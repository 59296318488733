<template>
  <div class="px-2" id="page-dashboard">
    <!-- 6 instsances per row from "instances" array -->

    <div v-for="(row, index) in num_rows" :key="index" class="row">
      <div v-for="(instance, index) in instances.slice(index * 6, (index + 1) * 6)" :key="index" class="col-2">
        <DashboardInstanceStatus :instance="instance" />
      </div>
    </div>

  </div>
</template>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import DashboardInstanceStatus from '@/view/pages/ml/dashboard/DashboardInstanceStatus.vue';

import dayjs from 'dayjs';

export default {
  name: 'DashboardInstances',
  components: {
    DashboardInstanceStatus
  },
  data() {
    return {
      interval_id: null,
      instances: []
    };
  },
  computed: {
    ...mapGetters(['currentCompanyId', 'currentPeriodId', 'settings', 'periods', 'isTHS']),
    num_rows() {
      return Math.ceil(this.instances.length / 6);
    }
  },
  mounted() {
    if (this.interval_id) {
      return;
    }

    this.interval_id = setInterval(() => {
      this.poll_instances();
    }, 5000);

    this.poll_instances();
  },
  watch: {
    currentCompanyId(newValue, oldValue) {
    
    },
    currentPeriodId(newValue, oldValue) {
    
    }
  },
  methods: {
    async poll_instances() {
      try {
        const res = await axios.get(`/ths/instance`);

        if (res.status === 200) {
          this.instances = res.data;
        }
      }
      catch (err) {
        console.error('poll_instances error', err);
      }
    }
  }
};
</script>

<style lang="scss" scoped>

:deep .col-2 {
  padding-left: 2px !important;
  padding-right: 2px !important;
}

  #page-dashboard {
    .custom-card-body-2-column {
      display: flex;
      flex-direction: row;
      flex-grow: 1;
      width: 100%;
      height: 100%;
      padding: 2rem 0rem;
      .title {
        flex: 1;
        position: relative;
        font-size: 2.2rem;
        font-weight: 600;
        color: #595d63;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          opacity: 0.08;
          position: absolute;
          z-index: 0;
          width: 120px;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
      .data {
        flex: 1;
        display: flex;
        align-items: center;
        margin-left: 3rem;
        .member-icon-1 {
          display: inline-block;
          width: 0.45rem;
          height: 0.45rem;
          flex: 0 0 0.45rem;
          border-radius: 100%;
          margin-bottom: 0.12rem;
          margin-right: 0.8rem;
          background-color: #93a2dd !important;
        }
        .member-icon-2 {
          display: inline-block;
          width: 0.45rem;
          height: 0.45rem;
          flex: 0 0 0.45rem;
          border-radius: 100%;
          margin-bottom: 0.12rem;
          margin-right: 0.8rem;
          background-color: #5d78ff !important;
        }
        .member-icon-3 {
          display: inline-block;
          width: 0.45rem;
          height: 0.45rem;
          flex: 0 0 0.45rem;
          border-radius: 100%;
          margin-bottom: 0.12rem;
          margin-right: 0.8rem;
          background-color: #646c9a !important;
        }
      }
    }
    .membership-acquisition {
      .gender-girl-line {
        width: 15px;
        height: 5px;
        border-radius: 3px;
        background-color: #5d78ff !important;
      }
      .gender-boy-line {
        width: 15px;
        height: 5px;
        border-radius: 3px;
        background-color: #93a2dd !important;
      }
      .gender-other-line {
        width: 15px;
        height: 5px;
        border-radius: 3px;
        background-color: #646c9a !important;
      }
    }
  }
</style>
